import React, { useState } from "react"

import "../../styles/component/menu.scss"

import SVGIcon from "../common/SVGIcon"

const Menu = ({ data }) => {
  const { title, subtitles, index,active} = data
  const [menuOpen, setMenuOpen] = useState(index === 0)
  
  return (
    <div className="menuitems">
      <div className={`title p14 ${active === title.value && 'active'}`}>
        <p
          onClick={() => {
            setMenuOpen(true)
            title.action()
          }}
        >
          {title.value}
        </p>
        <SVGIcon
          name={"arrow-down"}
          onClick={() => setMenuOpen(!menuOpen)}
          className={`position-absolute ${menuOpen && "open"}`}
        />
      </div>
      {menuOpen &&
        subtitles.map(subtitle => (
          <div onClick={subtitle.action} className={`subtitle p14  ${active === [title.value]+[subtitle.value] && 'active'}`}>
            <p>{subtitle.value}</p>
          </div>
        ))}
    </div>
  )
}

export default Menu
