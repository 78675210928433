import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Element, scroller } from "react-scroll"
import RightArrow from "../assets/svgs/carousel-right.svg"
import CarousalComponent from "../components/common/carousel-component"
import CTABox from "../components/common/CTA"
import Frame from "../components/common/frame"
import Navigation from "../components/navigation"
import DropDown from "../components/utility/dropdown_new"
import Menu from "../components/utility/menu"
import Slide from "../components/utility/slide_content"
import "../styles/pages/press-room.scss"

let PressRoom = props => {
  const [currentYear, setCurrentYear] = useState()
  const [currentDate, setCurrentDate] = useState("")

  function ScrollTo(val) {
    scroller.scrollTo(val, {
      offset: -150,
    })
  }

  function newfilteredMenu(data) {
    let Years = []

    data.map(news => {
      !Years.includes(news.position) && Years.push(news.position)
    })

    let resultObj = Years.map(year => {
      let Dates = []
      data.map(news => {
        if (year === news.position) {
          !Dates.includes(news.order) && Dates.push(news.order)
        }
      })
      return {
        title: {
          value: year,
          className: "title",
          action: () => {
            ScrollTo(year)
            setCurrentYear(year)
          },
        },
        subtitles: Dates.map(date => {
          return {
            value: date,
            className: "subtitle",
            action: () => {
              ScrollTo(year + date)
              setCurrentYear(year)
            },
          }
        }),
      }
    })

    return resultObj
  }

  function filteredMenu(data) {
    let ResultObj = []

    let Data = newfilteredMenu(data)

    if (currentYear === undefined) {
      setCurrentYear(Data[0].title.value)
    }

    Data.map(dt => {
      const { title, subtitles } = dt
      ResultObj.push(title)
      subtitles.map(st => ResultObj.push(st))
    })

    return ResultObj
  }

  return (
    <>
      <StaticQuery
        query={graphql`
          query NewsRoomPage {
            SuperOps {
              pages(where: { title: "Press Room" }) {
                title
                pageBlock {
                  id
                  content {
                    html
                    text
                  }
                }
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }

                ctaBoxes {
                  type
                  theme
                  heading {
                    html
                  }
                  description {
                    html
                  }
                  primaryButtonText
                  primaryButtonLink
                  secondaryButtonText
                  secondaryButtonLink
                }
              }

              peopleList(
                orderBy: createdAt_DESC
                where: { page: "Press Room" }
              ) {
                isFeatured
                position
                order
                company
                name
                title
                picture {
                  url
                }
                linkedInUrl
                order
                thumbnail {
                  url
                }
              }
            }
          }
        `}
        render={data =>
          data.SuperOps.pages.map(page => {
            const { title, seo, pageBlock, navigationBlock, ctaBoxes } = page
            const MenuData = newfilteredMenu(data.SuperOps.peopleList)
            return (
              <div className="press-room">
                <Frame
                  title={seo.title}
                  description={seo.description}
                  keywords={seo.keywords}
                  ogTitle={seo.title}
                  ogDescription={seo.description}
                  ogImage={seo.image}
                  ogType="website"
                >
                  <header>
                    <Navigation links={navigationBlock} page="Home" />
                  </header>
                  <main>
                    <section className="hero">
                      <Container>
                        <Slide delay="200">
                          <div className="breadcrumb p14">
                            {parse(pageBlock[0].content.html)}
                          </div>
                        </Slide>
                        <Slide delay="200">
                          <div className="title">
                            {parse(pageBlock[1].content.html)}
                          </div>
                        </Slide>
                      </Container>
                    </section>
                    <section className="featured-articles">
                      <Container>
                        <div className="content">
                          <Slide delay="200">
                            <div className="title p16">
                              {parse(pageBlock[2].content.html)}
                            </div>
                          </Slide>
                          <Slide delay="200">
                            <CarousalComponent
                              items={1}
                              status
                              statusText=""
                              swipeable
                            >
                              {data.SuperOps.peopleList
                                .filter((d) => d.isFeatured === "Yes").reverse()
                                .map((data, i) => {
                                  const {
                                    title,
                                    name,
                                    picture,
                                    linkedInUrl,
                                    company,
                                    thumbnail,
                                  } = data
                                  return (
                                    <a target="_blank" href={linkedInUrl}>
                                      <div className="news-card featured">
                                        <div className="image">
                                          <img
                                            src={picture.url}
                                            className="w-100"
                                          />
                                          <img
                                            className="logo position-absolute"
                                            src={thumbnail.url}
                                          />
                                        </div>
                                        <div className="content">
                                          <div className="title">
                                            <h5>{title}</h5>
                                          </div>
                                          <div className="bottom">
                                            <div className="left p14">
                                              <span>reporter</span>
                                              <p>{name}</p>
                                            </div>
                                            <div className="right p12">
                                              <p>{company}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  )
                                })}
                            </CarousalComponent>
                          </Slide>
                        </div>
                      </Container>
                    </section>
                    <section className="in-the-news">
                      <Container>
                        <Row>
                          <Col className="left">
                            <Slide delay="200">
                              <div className="menu">
                                <div className="title p14">
                                  {parse(pageBlock[3].content.html)}
                                </div>
                                <div className="desktop">
                                  {MenuData.map((menu, i) => {
                                    return <Menu data={{ ...menu, index: i }} />
                                  })}
                                </div>
                                <div className="mobile-dropdown">
                                  <DropDown
                                    currentValue={currentYear}
                                    menuItems={filteredMenu(
                                      data.SuperOps.peopleList
                                    )}
                                  />
                                </div>
                              </div>
                            </Slide>
                          </Col>
                          <Col className="right">
                            {MenuData.map(menu => {
                              return (
                                <>
                                  <span className="p14">
                                    <Element
                                      name={menu.title.value}
                                      className="year"
                                    >
                                      <Slide delay="200">
                                        <p>{menu.title.value}</p>
                                      </Slide>
                                    </Element>
                                  </span>
                                  {menu.subtitles.map(st => {
                                    return (
                                      <Element
                                        name={menu.title.value + st.value}
                                      >
                                        <Slide delay="200">
                                          <h4 className="date fw-bold">
                                            {st.value}
                                          </h4>
                                        </Slide>
                                        <div className="news-grid">
                                          {data.SuperOps.peopleList
                                            .filter(
                                              dt =>
                                                dt.order === st.value &&
                                                dt.position === menu.title.value
                                            )
                                            .reverse()
                                            .map(news => {
                                              const {
                                                title,
                                                thumbnail,
                                                name,
                                                linkedInUrl,
                                              } = news
                                              return (
                                                <a
                                                  target="_blank"
                                                  href={linkedInUrl}
                                                >
                                                  <Slide delay="200">
                                                    <div className="news-card">
                                                      <img
                                                        className="logo"
                                                        src={thumbnail.url}
                                                      />
                                                      <div className="title alt">
                                                        <h6>{title}</h6>
                                                      </div>
                                                      <div className="bottom position-absolute">
                                                        <div className="author p12">
                                                          <p>{name}</p>
                                                        </div>
                                                        <RightArrow className="arrow" />
                                                      </div>
                                                    </div>
                                                  </Slide>
                                                </a>
                                              )
                                            })}
                                        </div>
                                      </Element>
                                    )
                                  })}
                                </>
                              )
                            })}
                          </Col>
                        </Row>
                      </Container>
                    </section>
                    <section className="cta-section">
                      <Container>
                        <Slide delay="200">
                          <CTABox lgRight="4" data={ctaBoxes} />
                        </Slide>
                      </Container>
                    </section>
                  </main>
                </Frame>
              </div>
            )
          })
        }
      />
    </>
  )
}

export default PressRoom
